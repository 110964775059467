import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import removeAccents from '../helpers/removeAccents';

interface IResponse {
  status: string;
  payload: any;
}

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  private URL_BASE = environment.apiUrl;
  private URL_USER = environment.urlUser;
  private URL_RESET_PASSWORD = environment.urlResetPassword;
  private URL_FAQ = environment.urlFaq;
  private URL_INFOS = environment.urlInfos;
  private URL_IMAGE = environment.urlImage;
  private URL_BANNERS = environment.urlBanner;
  private URL_BANNER_IMG_UPLOAD = environment.urlImageUpload;
  private URL_BANNER_APPROVED = environment.urlBannerApprove;
  private URL_STORE = environment.urlStore;
  private URL_STORE_IMG_UPLOAD = environment.urlImageUploadStore;
  private URL_EVENT = environment.urlEvent;
  private URL_EVENT_MANAGEMENT = environment.urlEventManagement;
  private URL_CONSUME_TICKET = environment.urlConsumeTicket;
  private URL_EVENT_IMG_UPLOAD = environment.urlEventImageUpload;
  private URL_EVENT_APPROVED = environment.urlEventApprove;
  private URL_OFFER = environment.urlOffer;
  private URL_UPLOAD = environment.urlUpload;
  private URL_GROUP_OFFER = environment.urlGroupOffer;
  private URL_OFFER_APPROVED = environment.urlOfferApprove;
  private URL_NOTIFICATIONS = environment.urlNotifications;
  private URL_NOTIFICATION_ACTIVATE = environment.urlNotificatioActivate;
  private URL_EMBED_TOKEN = environment.urlEmbedToken;
  private SHOPPING_ID = '{shopping_id}';

  // constructor(private _http: HttpClient) {}
  #http = inject(HttpClient);

  getInfos$() {
    return this.#http.get(this.URL_BASE + this.SHOPPING_ID + this.URL_INFOS);
  }
  updateInfos$(body: any) {
    return this.#http.put(
      this.URL_BASE + this.SHOPPING_ID + this.URL_INFOS,
      body
    );
  }
  shoppingImageUpload$(body: any) {
    return this.#http.post(
      this.URL_BASE + this.SHOPPING_ID + this.URL_INFOS + this.URL_IMAGE,
      body
    );
  }
  shoppingImageUpdate$(body: any, url: string) {
    return this.#http.put(
      this.URL_BASE + this.SHOPPING_ID + this.URL_INFOS + this.URL_IMAGE,
      body,
      {
        params: { old_url: url }
      }
    );
  }

  getFaq$() {
    return this.#http.get(this.URL_BASE + this.SHOPPING_ID + this.URL_FAQ);
  }
  createFaq$(body: any) {
    return this.#http.post(
      this.URL_BASE + this.SHOPPING_ID + this.URL_FAQ,
      body
    );
  }
  updateFaq$(body: any) {
    return this.#http.put(
      this.URL_BASE + this.SHOPPING_ID + this.URL_FAQ,
      body
    );
  }
  getShoppingConfig$() {
    return this.#http.get(this.URL_BASE + this.SHOPPING_ID + this.URL_INFOS + '/config');
  }
  getShoppingInfo$() {
    return this.#http.get(this.URL_BASE + this.SHOPPING_ID + this.URL_INFOS);
  }
  updateShoppingInfo$(body: any) {
    return this.#http.put(
      this.URL_BASE + this.SHOPPING_ID + this.URL_INFOS,
      body
    );
  }

  getBanners$() {
    return this.#http.get(this.URL_BASE + this.SHOPPING_ID + this.URL_BANNERS);
  }
  getBanner$(id: string) {
    return this.#http.get(
      this.URL_BASE + this.SHOPPING_ID + this.URL_BANNERS + '/' + id
    );
  }
  postBanners$(body: any) {
    return this.#http.post(
      this.URL_BASE + this.SHOPPING_ID + this.URL_BANNERS,
      body
    );
  }
  updateBanners$(id: any, body: any) {
    return this.#http.put(
      this.URL_BASE + this.SHOPPING_ID + this.URL_BANNERS + '/' + id,
      body
    );
  }
  approvedBanner$(id: any, newStatus: boolean) {
    return this.#http.post(
      this.URL_BASE + this.SHOPPING_ID + this.URL_BANNER_APPROVED + id,
      { is_approved: newStatus }
    );
  }
  deleteBanner$(id: string | number) {
    return this.#http.delete(
      this.URL_BASE + this.SHOPPING_ID + this.URL_BANNERS + '/' + id
    );
  }
  activationBanner$(id: string | number, newStatus: boolean) {
    return this.#http.put(
      this.URL_BASE + this.SHOPPING_ID + this.URL_BANNERS + '/' + id,
      {},
      { params: { enable: newStatus } }
    );
  }
  bannerImageUpload$(body: any, category: string) {
    return this.#http.post(
      this.URL_BASE + this.SHOPPING_ID + this.URL_BANNER_IMG_UPLOAD,
      body,
      {
        params: { type: category },
      }
    );
  }
  bannerImageUpdate$(
    body: any,
    url: string,
    bannerId: string,
    category: string
  ) {
    const headers = {} as any;
    headers.filename = bannerId + new Date().toDateString();
    // headers.set('access-control-allow-origin', '*');
    return this.#http.put(
      this.URL_BASE +
        this.SHOPPING_ID +
        this.URL_BANNER_IMG_UPLOAD +
        '/' +
        bannerId,
      body,
      {
        params: { old_url: url, type: category },
        headers,
      }
    );
  }

  //stores
  getStores$() {
    return this.#http.get(this.URL_BASE + this.SHOPPING_ID + this.URL_STORE);
  }
  getStore$(id: string) {
    return this.#http.get(
      this.URL_BASE + this.SHOPPING_ID + this.URL_STORE + '/' + id
    );
  }
  postStore$(body: any) {
    return this.#http.post(
      this.URL_BASE + this.SHOPPING_ID + this.URL_STORE,
      body
    );
  }
  putStore$(id: any, body: any) {
    return this.#http.put(
      this.URL_BASE + this.SHOPPING_ID + this.URL_STORE + '/' + id,
      body
    );
  }
  deleteStore$(id: any) {
    return this.#http.delete(
      this.URL_BASE + this.SHOPPING_ID + this.URL_STORE + '/' + id
    );
  }
  storeImageUpload$(body: any, type: string) {
    return this.#http.post(
      this.URL_BASE + this.SHOPPING_ID + this.URL_STORE_IMG_UPLOAD,
      body,
      {
        params: { type: type }
      }
    );
  }
  storeImageUpdate$(body: any, type: string, url: string) {
    return this.#http.put(
      this.URL_BASE + this.SHOPPING_ID + this.URL_STORE_IMG_UPLOAD,
      body,
      {
        params: { type: type, old_url: url }
      }
    );
  }

  getEvents$() {
    return this.#http.get(this.URL_BASE + this.SHOPPING_ID + this.URL_EVENT);
  }
  getEvent$(id: string) {
    return this.#http.get(
      this.URL_BASE + this.SHOPPING_ID + this.URL_EVENT + '/' + id
    );
  }
  putEvents$(id: any, body: any) {
    return this.#http.put(
      this.URL_BASE + this.SHOPPING_ID + this.URL_EVENT + '/'+ id,
      body
    );
  }
  postEvents$(body: any) {
    return this.#http.post(
      this.URL_BASE + this.SHOPPING_ID + this.URL_EVENT,
      body
    );
  }
  eventImageUpload$(body: any) {
    return this.#http.post(
      this.URL_BASE + this.SHOPPING_ID + this.URL_EVENT_IMG_UPLOAD,
      body
    );
  }
  eventImageUpdate$(body: any, url: string, eventId: string) {
    return this.#http.put(
      this.URL_BASE + this.SHOPPING_ID + this.URL_EVENT_IMG_UPLOAD +
      '/' +
      eventId,
      body,
      {
        params: { old_url: url }
      }
    );
  }
  deleteEvent$(id: string | number, cancel_reason: string) {
    return this.#http.delete(
      this.URL_BASE + this.SHOPPING_ID + this.URL_EVENT + '/'+ id, {body:{
        cancel_reason
      }}
    );
  }
  approvedEvent$(id: any, is_approved: boolean) {
    return this.#http.post(
      this.URL_BASE + this.SHOPPING_ID + this.URL_EVENT_APPROVED + id,
      { is_approved }
    );
  }

  //event management
  getEventsManagement$() {
    return this.#http.get(
      this.URL_BASE + this.SHOPPING_ID + this.URL_EVENT_MANAGEMENT
    );
  }
  getEventSlots$(id: string) {
    return this.#http.get(
      this.URL_BASE + this.SHOPPING_ID + this.URL_EVENT_MANAGEMENT + '/' + id
    );
  }
  consumeEventTicket$(event_id: string, manual_code: string) {
    return this.#http.post(
      this.URL_BASE +
        this.SHOPPING_ID +
        this.URL_EVENT_MANAGEMENT +
        '/' +
        event_id +
        this.URL_CONSUME_TICKET,
      { manual_code }
    );
  }

  //Notifications
  getNotifications$() {
    return this.#http.get(
      this.URL_BASE + this.SHOPPING_ID + this.URL_NOTIFICATIONS
    );
  }
  getNotification$(id: string) {
    return this.#http.get(
      this.URL_BASE + this.SHOPPING_ID + this.URL_NOTIFICATIONS + '/' + id
    );
  }
  postNotification$(body: any) {
    return this.#http.post(
      this.URL_BASE + this.SHOPPING_ID + this.URL_NOTIFICATIONS,
      body
    );
  }
  putNotification$(notificationId: any, body: any) {
    return this.#http.put(
      this.URL_BASE +
        this.SHOPPING_ID +
        this.URL_NOTIFICATIONS +
        '/' +
        notificationId,
      body
    );
  }
  deleteNotification$(notificationId: any) {
    return this.#http.delete(
      this.URL_BASE +
        this.SHOPPING_ID +
        this.URL_NOTIFICATIONS +
        '/' +
        notificationId
    );
  }
  activateNotification$(id: any, is_active: boolean) {
    return this.#http.put(
      this.URL_BASE +
        this.SHOPPING_ID +
        this.URL_NOTIFICATIONS +
        this.URL_NOTIFICATION_ACTIVATE +
        '/' +
        id,
      {},
      { params: { enable: is_active } }
    );
  }
  approveNotification$(id: any, is_approved: boolean) {
    return this.#http.post(
      this.URL_BASE +
        this.SHOPPING_ID +
        this.URL_NOTIFICATIONS +
        '/status/' +
        id,
      { is_approved }
    );
  }

  //users
  getUsers$() {
    // return this.http.get('https://jsonplaceholder.typicode.com/users');
    return this.#http.get(this.URL_BASE + this.SHOPPING_ID + this.URL_USER);
  }
  getUser$(id: string) {
    // return this.http.get('https://jsonplaceholder.typicode.com/users');
    return this.#http.get(
      this.URL_BASE + this.SHOPPING_ID + this.URL_USER + '/' + id
    );
  }
  postUser$(body: any) {
    return this.#http.post(
      this.URL_BASE + this.SHOPPING_ID + this.URL_USER,
      body
    );
  }
  deleteUser$(id: any) {
    return this.#http.delete(
      this.URL_BASE + this.SHOPPING_ID + this.URL_USER + '/' + id
    );
  }
  updateUser$(id: any, body: any) {
    return this.#http.put(
      this.URL_BASE + this.SHOPPING_ID + this.URL_USER + '/' + id,
      body
    );
  }
  
  //offers
  getOffers$() {
    return this.#http.get(this.URL_BASE + this.SHOPPING_ID + this.URL_OFFER, {
    });
  }
  getOffer$(uuid: string) {
    return this.#http.get(
      this.URL_BASE + this.SHOPPING_ID + this.URL_OFFER + '/' + uuid
    );
  }
  getGroupOffers$() {
    return this.#http.get(
      this.URL_BASE + this.SHOPPING_ID + this.URL_GROUP_OFFER
    );
  }
  getGroupOffer$(uuid: string) {
    return this.#http.get(
      this.URL_BASE + this.SHOPPING_ID + this.URL_GROUP_OFFER + '/' + uuid
    );
  }
  getStoreOffers$() {
    return this.#http.get(
      this.URL_BASE + this.SHOPPING_ID + this.URL_OFFER + this.URL_STORE
    );
  }

  postOfferCSV$(file: any) {
    const normalizedFileName = removeAccents(file.name);
    const body = new File([file], normalizedFileName, { type: file.type });
    return this.#http.post(
      this.URL_BASE + this.SHOPPING_ID + this.URL_OFFER + this.URL_UPLOAD,
      body,
      {
        headers:{
          "type": 'text/csv',
          'filename': body.name
        }
      }
    );
  }
  
  postOffer$(body: any) {
    return this.#http.post(
      this.URL_BASE + this.SHOPPING_ID + this.URL_OFFER,
      body
    );
  }

  putOffer$(couponId: any, body: any) {
    return this.#http.put(
      this.URL_BASE + this.SHOPPING_ID + this.URL_OFFER + '/' + couponId,
      body
    );
  }

  approveOffer$(id: any, body: any) {
    return this.#http.post(
      this.URL_BASE + this.SHOPPING_ID + this.URL_OFFER_APPROVED + id,
      body
    );
  }
  deleteOffer$(id: any) {
    return this.#http.delete(
      this.URL_BASE + this.SHOPPING_ID + this.URL_OFFER + '/' + id
    );
  }
  pathDataAccessor(item: any, path: string): any {
    return path.split('.').reduce((accumulator: any, key: string) => {
      return accumulator ? accumulator.data_info[key] : undefined;
    }, item);
  }

  //power bi
  getPowerBiToken$(){
    return this.#http.get(
      this.URL_BASE + this.SHOPPING_ID + this.URL_EMBED_TOKEN
    );
  }
}
